/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../../_metronic/partials'
import {getFilterProduct, getFilterMarket} from '../core/_request'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {initialQueryState} from '../../../_metronic/helpers'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import DatePicker from 'react-datepicker'

type Props = {
  className: string
}

const PaymentReceiveGraph: React.FC<Props> = ({className}) => {
  //
  const [data, setData] = useState<number[]>([])
  const [categories, setCategories] = useState<string[]>([])
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [product, setProduct] = useState<any>([])
  const [market, setMarket] = useState<any>([])
  const [productid, setProductId] = useState<string | undefined>()
  const [marketid, setMarketId] = useState<string | undefined>()
  const [dateFormatId, setDateFormatId] = useState<any>()
  const {mode} = useThemeMode()
  const {updateState} = useQueryRequest()
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange

  const dataResponse = useQueryResponse()
  if (dataResponse.response && dataResponse.response.data) {
  }

  // const [data, setData] = useState(dataResponse.response?.data[0]);
  // const [categories, setCategories] = useState(dataResponse.response?.data[1]);
  const getData = () => {
    // Define the date
    const dateRangeInput = document.getElementById('Daterange') as HTMLInputElement;
    const date = dateFormatId == 4 ? dateRangeInput.value : undefined; // Undefined will omit the property
  
    // Create the filterData object and conditionally include the date
    const filterData: any = {
      productid,
      marketid,
      dateFormatId,
      ...(date && { date }) // Only add date if it exists
    };
  
    // Set the filter data inside the updateState call
    updateState({
      filter: filterData,
      ...initialQueryState,
    });
  };
  
  
  useEffect(() => {
    getFilterProduct().then((res: any) => {
      setProduct(res.data.data)
    })

    getFilterMarket().then((res: any) => {
      setMarket(res.data.data)
    })
  }, [])

  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }
    //
    const height = parseInt(getCSS(chartRef.current, 'height'))
    const chart = new ApexCharts(chartRef.current, getChartOptions(height, categories, data))
    if (chart) {
      chart.render()
    }
    return chart
  }

  useEffect(() => {
    const responseData = dataResponse.response?.data

    if (responseData && responseData.length >= 2) {
      const [receivedData, receivedCategories] = responseData

      if (Array.isArray(receivedData) && Array.isArray(receivedCategories)) {
        setData(receivedData) // Set entire data array
        setCategories(receivedCategories) // Set entire categories array
      } else {
        console.error('Received data is not in the expected format')
      }
    }
  }, [dataResponse])

  useEffect(() => {
    const chart = refreshMode()
    //
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, categories, data])

  return (
    <div>
      <div>
        <div className='row  col-12'>
          <div className='col-3'>
            <div className='mb-4'>
              <label className='form-label fs-6 fw-bold'> Product:</label>
              <select
                className='form-select form-select-solid fw-bolder'
                style={{backgroundColor: 'white', width: '200px'}}
                onChange={(e) => setProductId(e.target.value)}
                value={productid}
              >
                <option value=''>All</option>
                {product?.map((item: any, i: any) => {
                  return (
                    <option key={i} value={item.id}>
                      {item.name}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className='col-3 '>
            <div className='mb-4'>
              <label className='form-label fs-6 fw-bold'> Market:</label>
              <select
                className='form-select form-select-solid fw-bolder'
                style={{backgroundColor: 'white', width: '200px'}}
                placeholder='Market'
                onChange={(e) => setMarketId(e.target.value)}
                value={marketid}
              >
                <option value=''>All</option>
                {market?.map((item: any, i: any) => {
                  return (
                    <option key={i} value={item.id}>
                      {item.name}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>

          <div className='col-3  '>
            <div className='mb-4'>
              <label className='form-label fs-6 fw-bold'>DateFormate:</label>
              <select
                className='form-select form-select-solid fw-bolder'
                style={{backgroundColor: 'white', width: '200px'}}
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setDateFormatId(e.target.value)}
                value={dateFormatId}
              >
                <option value=''>All</option>
                <option value='0'>last 7 days</option>
                <option value='1'>last 30 days</option>
                <option value='2'>last 12 month</option>
                <option value='3'>last 5 year</option>
                <option value='4'>Custom</option>
              </select>
            </div>
          </div>

          <>{console.log('dateFormatId == 4', dateFormatId == 4)}</>

          {dateFormatId == 4 && (
            <div className='col-3  '>
              <div className='mb-4'>
                <label className='form-label fs-6 fw-bold'>Date Range:</label>
                <div onClick={(e) => e.stopPropagation()}>
                  <DatePicker
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 bg-light'
                    id='Daterange'
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(event: any) => {
                      setDateRange(event)
                    }}
                    isClearable={true}
                    dateFormat='dd/MM/yyyy'
                  />
                </div>
              </div>
            </div>
          )}

          <div className='col-3  '>
            <div className='mt-5 mb-2'>
              <button className='btn  fw-bolder btn-primary  me-1' onClick={getData}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Product Price</span>
            {/* <span className='text-muted fw-semibold fs-7'>Payment received from client based on each month</span> */}
          </h3>

          {/* begin::Toolbar */}
          <div className='card-toolbar' data-kt-buttons='true'>
            {/* <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'
            id='kt_charts_widget_3_year_btn'
          >Current Year
          </a> */}
          </div>
          {/* end::Toolbar */}
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body'>
          {/* begin::Chart */}
          <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '150px'}}></div>
          {/* end::Chart */}
        </div>
        {/* end::Body */}
      </div>
    </div>
  )
}

export {PaymentReceiveGraph}

function getChartOptions(height: number, categories: string[], data: number[]): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const baseColor = getCSSVariableValue('--kt-warning')
  const lightColor = getCSSVariableValue('--kt-warning-light')

  return {
    series: [
      {
        name: 'Product Price',
        data: data,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '' + val + ' Price '
        },
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}
