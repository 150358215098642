import React, {useEffect, useMemo, useRef, useState} from 'react'
import {getPlan, reports} from '../core/_requests'
import DatePicker from 'react-datepicker'
import Select, {Theme} from 'react-select'
import 'react-datepicker/dist/react-datepicker.css'
import './report.css'
import {useDownloadExcel} from 'react-export-table-to-excel'
import {useThemeMode} from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'


export type PaginationState = {
  page: number;
  items_per_page: 10 | 30 | 50 | 100;
  total?: number;
  links?: Array<{
    label: string;
    active: boolean;
    url: string | null;
    page: number | null;
  }>;
};

type PageSize = 10 | 30 | 50 | 100;
const ReportContent = () => {
  const tableRef = useRef(null)
  const [plan, setPlan] = useState<any>([])
  const [planid, setplanid] = useState('')
  const [statusid, setstatusid] = useState('')
  const [mobile, setmobile] = useState('')
  const [report, setReport] = useState<any>([])

  const [loading, setLoading] = useState(false)
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const [expiredDateRange, setExpiredDateRange] = useState([null, null])
  const [expiredStartDate, expiredEndDate] = expiredDateRange

  const [btnClearDisable, setbtnClearDisable] = useState(true)
  const [planstatus, setplanstatus] = useState<any>([])
  const {mode} = useThemeMode()
  const [alertMessage, setAlertMessage] = useState<string | null>(null)

  useEffect(() => {
    getPlan().then((res: any) => {
      const mappedOptions = res.data.Data.map((com: {id: any; name: any}) => ({
        value: com.id,
        label: com.name,
      }))

      const allOption = {value: 0, label: 'All Plans'}
      const optionsWithAll = [allOption, ...mappedOptions]
      setPlan(optionsWithAll)
    })

    const statusOption = [
      {value: '', label: 'All Plan Status'},
      {value: false, label: 'Active'},
      {value: true, label: 'Expired'},
    ]
    setplanstatus(statusOption)

    reports().then((res: any) => {
      setReport(res.data.Data)
      setbtnClearDisable(false)
    })
  }, [])

  var DatefileName = new Date()
  var fileName =
    DatefileName.getFullYear() + '-' + (DatefileName.getMonth() + 1) + '-' + DatefileName.getDate()

  const {onDownload} = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Plan_Report_${fileName}`,
    sheet: 'Users',
  })

  //dropdown
  if (mode === 'dark') {
    var customStyles = {
      control: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        borderColor: '#393944',
        boxShadow: state.isFocused ? '0 0 0 1px #009ef7' : 'none',
        '&:hover': {
          color: 'red',
        },
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#009ef7' : 'var(--kt-app-bg-color)',
        color: state.isFocused ? 'white' : 'var(--kt-input-solid-color)',
      }),
      menu: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        marginTop: 5,
        borderRadius: 5,
      }),
      placeholder: (provided: any, state: any) => ({
        ...provided,
        color: 'var(--kt-input-solid-color)',
      }),
      singleValue: (provided: any, state: any) => ({
        ...provided,
        color: state.isFocused ? 'var(--kt-input-solid-color)' : 'var(--kt-input-solid-color)',
      }),
      indicatorSeparator: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-input-solid-color)',
      }),
    }
  } else {
    var customStyles = {
      control: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        borderColor: '#c5c5c5',

        boxShadow: state.isFocused ? '0 0 0 1px #009ef7' : 'none',
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#009ef7' : 'var(--kt-app-bg-color)',
        color: state.isFocused ? 'white' : 'var(--kt-input-solid-color)',
      }),
      menu: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        marginTop: 5,
        borderRadius: 5,
      }),
      placeholder: (provided: any, state: any) => ({
        ...provided,
        color: 'var(--kt-input-solid-color)',
      }),
      singleValue: (provided: any, state: any) => ({
        ...provided,
        color: state.isFocused ? 'var(--kt-input-solid-color)' : 'var(--kt-input-solid-color)',
      }),
      indicatorSeparator: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: '#c5c5c5',
      }),
    }
  }

  const [selectedOption, setSelectedOption] = useState<any>({value: 0, label: 'All Plans'})
  const [planselectedOption, setplanselectedOption] = useState<any>({
    value: '',
    label: 'All Plan Status',
  })

  const handleSelectChange = (selectedOption: any) => {
    setSelectedOption(selectedOption)
    var text = selectedOption?.label
    var val = selectedOption?.value
    setplanid(val)
  }

  const planhandleSelectChange = (planselectedOption: any) => {
    setplanselectedOption(planselectedOption)
    var val = planselectedOption?.value

    if (val === 0) {
      var statusVal = typeof String(val)
      setstatusid(statusVal)
    } else {
      setstatusid(val)
    }
  }

  const companyReport = () => {
    let createdDate = (document.getElementById('Daterange') as HTMLInputElement).value
    let expiredDate = (document.getElementById('DaterangeExpired') as HTMLInputElement).value
    setAlertMessage('')
    const data = {
      planId: planid,
      isExpired: statusid,
      mobile: mobile,
      createdDate,
      expiredDate,
    }

    reports(data).then((res: any) => {
      setReport(res.data.Data);
      setbtnClearDisable(false);
      setCurrentPage(1);
    })
  }

  const clearReport = () => {
    setSelectedOption({value: 0, label: 'All Plans'})
    setplanselectedOption({
      value: '',
      label: 'All Plan Status',
    })
    setplanid('')
    setstatusid('')
    setExpiredDateRange([null, null])
    setDateRange([null, null])
    setmobile('')
    reports().then((res: any) => {
      setReport(res.data.Data)
      setbtnClearDisable(false)
    })
  }
  const [itemsPerPage, setItemsPerPage] = useState(10) // Default items per page
  const [currentPage, setCurrentPage] = useState(1)

  // Calculate the total number of pages
  const totalPages = Math.ceil(report.length / itemsPerPage)

  // Get the current items to display
  const startIndex = (currentPage - 1) * itemsPerPage
  const currentItems = useMemo(() => {
    return report.slice(startIndex, startIndex + itemsPerPage);
  }, [report, startIndex, itemsPerPage]);
  
  // Function to handle page change
  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber)
  }

  // Function to handle items per page change
  const handleItemsPerPageChange = (event: any) => {
    setItemsPerPage(Number(event.target.value))
    setCurrentPage(1) // Reset to the first page whenever items per page is changed
  }

  return (
    <>
      <div className='card '>
        {/* <form onSubmit={formik.handleSubmit} noValidate className='form'> */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='row mb-10'>
            <div className='col-lg-3'>
              <label className=' col-form-label  fw-bold fs-6'>Plan</label>
              <Select
                className='my-react-select-container'
                classNamePrefix='my-react-select'
                value={selectedOption}
                onChange={handleSelectChange}
                options={plan}
                isSearchable
                // onInputChange={handleInputChange}
                styles={customStyles}
              />
            </div>
            <div className='col-lg-3'>
              <label className=' col-form-label    fw-bold fs-6'>Plan Status</label>
              <Select
                className='my-react-select-container'
                classNamePrefix='my-react-select'
                value={planselectedOption}
                onChange={planhandleSelectChange}
                options={planstatus}
                isSearchable
                // onInputChange={handleInputChange}
                styles={customStyles}
              />
            </div>
            <div className='col-lg-3'>
              <label className=' col-form-label  fw-bold fs-6'>Mobile Number</label>
              <input
                type='text'
                placeholder='Search Mobile'
                value={mobile}
                onChange={(e) => {
                  setmobile(e.target.value)
                }}
                className='form-control form-control-lg form-control-solid'
              />
            </div>

            <div className='col-lg-3'>
              <label className=' col-form-label fw-bold fs-6'>Subscription Date</label>
              <DatePicker
                className='form-control form-control-lg form-control-solid'
                id='Daterange'
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update: any, e: any) => {
                  setDateRange(update)
                  e.stopPropagation()
                }}
                placeholderText='Subscription Date'
                isClearable={true}
                dateFormat='dd/MM/yyyy'
              />
              {alertMessage && <div className='text-danger'>{alertMessage}</div>}
            </div>

            <div className='col-lg-12 row' style={{float: 'right', paddingRight: '0px'}}>
              <div className='col-lg-3'>
                <label className=' col-form-label fw-bold fs-6'>Expired Date</label>
                <DatePicker
                  className='form-control form-control-lg form-control-solid'
                  id='DaterangeExpired'
                  selectsRange={true}
                  startDate={expiredStartDate}
                  endDate={expiredEndDate}
                  onChange={(update: any, e: any) => {
                    setExpiredDateRange(update)
                    e.stopPropagation()
                  }}
                  isClearable={true}
                  placeholderText='Expired Date'
                  dateFormat='dd/MM/yyyy'
                />
                {/* {alertMessage && <div className='text-danger'>{alertMessage}</div>} */}
              </div>
              <div className='col-lg-3 pt-12'>
                <button onClick={companyReport} className='btn btn-primary col-lg-12' type='submit'>
                  Search
                </button>
              </div>
              <div className='col-lg-3 pt-12'>
                <button
                  onClick={clearReport}
                  className='btn btn-primary col-lg-12'
                  disabled={btnClearDisable}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>

          <div className='table-responsive'>
            {/* begin::Table */}
            <table
              className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
              ref={tableRef}
            >
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-50px'>S.No.</th>
                  <th className='min-w-50px'>UID</th>
                  <th className='min-w-140px'>USER NAME</th>
                  <th className='min-w-140px'>MOBILE</th>
                  <th className='min-w-120px'>PLAN</th>
                  <th className='min-w-100px'>PLAN START</th>
                  <th className='min-w-100px'>PLAN END</th>
                  <th className='min-w-100px'>PLAN STATUS</th>
                  <th className='min-w-140px'>SUBSCRIPTION DATE</th>
                </tr>
              </thead>
              {loading && (
                <tbody>
                  <tr>
                    <td colSpan={9} style={{textAlign: 'center'}}>
                      Please wait...
                    </td>
                  </tr>
                </tbody>
              )}
              {!loading && (
                <tbody>
                  {currentItems.length > 0 ? (
                    currentItems.map((item: any, i: any) => (
                      <tr key={i}>
                        <td>{startIndex + i + 1}</td> {/* Serial Number */}
                        <td>{item?.userId}</td>
                        <td>{item?.user?.name}</td>
                        <td>{item?.user?.mobile}</td>
                        <td>{item?.plan?.name}</td>
                        <td>
                          {new Date(item.startDate).toLocaleDateString('en-GB', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                          })}
                        </td>
                        <td>
                          {new Date(item.endDate).toLocaleDateString('en-GB', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                          })}
                        </td>
                        <td>
                          {item.isExpired ? (
                            <span className='badge badge-danger'>Expired</span>
                          ) : (
                            <span className='badge badge-success'>Active</span>
                          )}
                        </td>
                        <td>
                          {new Date(item.createdAt).toLocaleDateString('en-GB', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                          })}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={9}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          <h6 className='text-muted fw-bold'>No matching records found</h6>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
              {/* end::Table body */}
            </table>
            {/* Pagination Controls */}
            <div className='pagination-container d-flex flex-column flex-md-row justify-content-between align-items-center mb-3'>
              <div className='form-group mb-2 mb-md-0'>
                <label htmlFor='itemsPerPage' className='form-label'>
                  Items per page:
                </label>
                <select
                  id='itemsPerPage'
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                  className='form-select'
                  style={{maxWidth: '120px'}}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>

              <div className='btn-group'>
                <button
                  className='btn btn-outline-info rounded-pill'
                  onClick={() => handlePageChange(1)}
                  disabled={currentPage === 1}
                >
                  First
                </button>
                <button
                  className='btn btn-outline-info rounded-pill'
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>

                {Array.from({length: totalPages}, (_, i) => (
                  <button
                    key={i}
                    className={`btn btn-outline-info rounded-pill ${
                      currentPage === i + 1 ? 'active' : ''
                    }`}
                    onClick={() => handlePageChange(i + 1)}
                  >
                    {i + 1}
                  </button>
                ))}

                <button
                  className='btn btn-outline-info rounded-pill'
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
                <button
                  className='btn btn-outline-info rounded-pill'
                  onClick={() => handlePageChange(totalPages)}
                  disabled={currentPage === totalPages}
                >
                  Last
                </button>
              </div>
            </div>
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
        {/* </form> */}
      </div>
    </>
  )
}

export {ReportContent}
